import React, {Component, Suspense} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {ToastContainer} from "react-toastify";
import './scss/style.scss'
import './assets/css/fontawesome/css/fontawesome-6.min.css'
import 'react-toastify/dist/ReactToastify.css';
import '@nosferatu500/react-sortable-tree/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const EsqueciMinhaSenha = React.lazy(() => import('./views/pages/login/EsqueciMinhaSenha'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/" name="Login" element={<Login/>}/>
              <Route exact path="/recuperar-senha" name="EsqueciMinhaSenha" element={<EsqueciMinhaSenha/>}/>
              <Route exact path="/404" name="Page 404" element={<Page404/>}/>
              <Route exact path="/500" name="Page 500" element={<Page500/>}/>
              <Route path="*" name="Home" element={<DefaultLayout/>}/>
            </Routes>
            <ToastContainer/>
          </Suspense>
        </BrowserRouter>
    )
  }
}

export default App
